html {
  background-color: #eeece4;
}

.box {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Chrome, Safari 対応 */
.box::-webkit-scrollbar {
  display: none;
}

p {
  font-family: "japan_l";
}

h2 {
  font-family: "japan_l";
  color: #707070;
}

h3 {
  font-family: japan_b;
}

@font-face {
  font-family: "thin";
  src: url(../font/NotoSansJP-Thin.otf);
}

.japanese_thin {
  font-family: thin;
}

@font-face {
  font-family: "japan_l";
  src: url(../font/Koruri-Light.ttf);
}

.japanese_L {
  font-family: japan_l;
}

@font-face {
  font-family: "japan_b";
  src: url(../font/Koruri-Bold.ttf);
}

@font-face {
  font-family: "japan_r";
  src: url(../font/Koruri-Regular.ttf);
}

.japanese_B {
  font-family: japan_b;
}

.japanese_R {
  font-family: japan_r;
}

.menu_box {
  min-height: 80vh;
  min-height: 80dvh;
}
